import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Form } from 'react-bootstrap';

const EditPopup = ({ show, onCancle, entity, entityObj, onEdit }) => {

    const [entityValue, setEntityValue] = useState('');

    const handleEntityValueChange = (e) => {
        setEntityValue(e.target.value);
    }

    const handleCancle = () => {
        onCancle();
        setEntityValue('');
    };

    const handleEdit = () => {
        onEdit(entityValue);
        setEntityValue('');
    };


    return (
        <div>
            <Modal show={show} onHide={handleCancle} centered>
                <Modal.Header closeButton style={{ fontFamily: "Nunito" }}>
                    <Modal.Title>Edit {entity.startsWith("collection") ? "Collection" : entity}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table style={{ width: "100%", fontFamily: "Nunito" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "20%" }}>{entity.startsWith("collection") ? "Collection Name" : entity}</td>
                                <td >:</td>
                                <td style={{ width: "95%", float: "right" }}>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        placeholder={`Enter ${entity}`}
                                        value={entityObj?.collectionName}
                                        disabled
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "20%", textWrap: "nowrap" }}>New {entity.startsWith("collection") ? "Collection Name" : entity}</td>
                                <td >:</td>
                                <td style={{ width: "95%", float: "right" }}>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        placeholder={`Enter New ${entity}`}
                                        value={entityValue}
                                        onChange={handleEntityValueChange}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "center" }}>
                    <Button disabled={entityValue == ''} style={{ fontFamily: "Nunito" }} variant="secondary" onClick={handleEdit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditPopup;