import React, { useState, useEffect } from 'react';
import './css/CollectionDetailsScreen.css';
import SearchableSelect from './SearchableSelect.jsx';
import { Table, Button, Form, Spinner } from 'react-bootstrap';
import { BASE_URL } from '../lib/common.js';
import { Link } from 'react-router-dom';
import DownloadButton from './DownloadButton.jsx';
import ConformationPopup from './ConformationPopup.jsx';
import EditPopup from './EditPopup.jsx';

import { useAuth } from "react-oidc-context";

const CollectionDetailsScreen = ({ userRoles }) => {

    const auth = useAuth();
    const [userData, setUserData] = useState(null);

    const [canCreate, setCanCreate] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    const [deletePopup, setDeletePopup] = useState(false);
    const [editPopup, setEditPopup] = useState(false);
    const [currCollection, setCurrCollection] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [categories, setCategories] = useState([]);
    const [collectionOps, setCollectionOps] = useState([]);
    const [collections, setCollections] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCollection, setSelectedCollection] = useState('');

    const pageSize = 10;
    const [currPageNo, setCurrPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }

    const handleSelectLocation = (option) => {
        setSelectedLocation(option);
    };

    const handleSelectCategory = (option) => {
        setSelectedCategory(option);
    };

    const handleSelectCollection = (option) => {
        setSelectedCollection(option);
    };

    const fliterCollection = () => {
        console.log("Filterd!");
    }

    const fetchNextPage = () => {
        setCurrPageNo(currPageNo + 1);
        // setHasNext((currPageNo + 1 < totalPages) ? true : false);
        // setHasPrev((currPageNo + 1 > 1) ? true : false);
    }

    const fetchPrevPage = () => {
        setCurrPageNo(currPageNo - 1);
        // setHasNext((currPageNo - 1 < totalPages) ? true : false);
        // setHasPrev((currPageNo - 1 > 1) ? true : false);
    }

    const handleCurrPageNoChange = (e) => {
        const newPageNo = parseInt(e.target.value, 10);
        setCurrPageNo(newPageNo);
    };

    const handleCurrPageNo = (e) => {
        let newPageNo = parseInt(e.target.value, 10);
        newPageNo = (isNaN(newPageNo) || newPageNo < 1 || newPageNo > totalPages) ? 1 : newPageNo;
        setCurrPageNo(newPageNo);
    };

    const getLocations = async () => {
        try {
            const response = await fetch(`${BASE_URL}getLocations`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let locationOptions = [];
            for (let location of data) {
                locationOptions.push(location.locationName);
            }
            setLocations(locationOptions);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const getCategories = async () => {
        try {
            const response = await fetch(`${BASE_URL}getCategories?location_name=${selectedLocation}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let categoryOptions = [];
            for (let category of data) {
                categoryOptions.push(category.categoryName);
            }
            setCategories(categoryOptions);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const getCollectionOptions = async () => {
        try {
            const response = await fetch(`${BASE_URL}getCollectionOptions?location_name=${selectedLocation}&&category_name=${selectedCategory}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let collectionOptions = [];
            for (let collection of data) {
                collectionOptions.push(collection.collectionName);
            }
            setCollectionOps(collectionOptions);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const getCollections = async () => {
        try {
            const response = await fetch(`${BASE_URL}getCollections?location_name=${selectedLocation}&&category_name=${selectedCategory}&&collection_name=${selectedCollection}&page=${currPageNo}&pageSize=${pageSize}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const { totalPages, hasNext, hasPrev, collections } = await response.json();
            let collectionOptions = [];
            for (let collection of collections) {
                collectionOptions.push(collection.collectionName);
            }
            setCollections(collections);
            setTotalPages(totalPages);
            setHasNext(hasNext);
            setHasPrev(hasPrev);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const getAllData = async () => {
        await getLocations();
        await getCategories();
        await getCollectionOptions();
        await getCollections();
    }

    useEffect(() => {
        getAllData();
    }, [])

    useEffect(() => {
        if (selectedLocation == '' || selectedLocation) {
            setCurrPageNo(1);
            setSelectedCategory('');
            getCategories();
            getCollectionOptions();
            getCollections();
        }
    }, [selectedLocation])

    useEffect(() => {
        if (selectedCategory == '' || selectedCategory) {
            setCurrPageNo(1);
            setSelectedCollection('');
            getCollectionOptions();
            getCollections();
        }
    }, [selectedCategory])

    useEffect(() => {
        if (selectedCollection == '' || selectedCollection) {
            setCurrPageNo(1);
            getCollections();
        }
    }, [selectedCollection])

    // const downloadImages = async (collectionName) => {
    //     setIsLoading(true);
    //     try {
    //         const response = await fetch(`${BASE_URL}collectionDownload?collection_name=${collectionName}`);
    //         if (!response.ok) {
    //             throw new Error('response was not ok');
    //         }
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = `${collectionName}.zip`;
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //         showAlert("Images downloaded successfully!", "lightgreen");
    //     } catch (error) {
    //         showAlert("Error while downloading images!", "lightcoral");
    //     }
    //     setIsLoading(false);
    // }

    const handleDelete = async (collection) => {
        setCurrCollection(collection);
        setDeletePopup(true);
    }

    const onDeleteCancle = async () => {
        setCurrCollection(null);
        setDeletePopup(false);
    }

    const onDelete = async () => {
        try {
            let obj = { "id": currCollection.id, "collection_name": currCollection.collectionName, "user_name": userData?.preferred_username };
            const response = await fetch(`${BASE_URL}deleteCollection`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj),
            });
            if (response.ok) {
                const { message } = await response.json();
                showAlert(message, "lightgreen");
                await getCollectionOptions();
                await getCollections();
            } else {
                const { message } = await response.json();
                showAlert(message, "lightcoral");
                console.error('Delete failed:', response.statusText);
            }
        } catch (error) {
            showAlert("Something wrong happen while deleting collection!", "lightcoral");
        }
        console.log("DELETING ->", currCollection);
        setCurrCollection(null);
        setDeletePopup(false);
    }

    const handleEdit = async (collection) => {
        setCurrCollection(collection);
        setEditPopup(true);
    }

    const onEditCancle = async () => {
        setCurrCollection(null);
        setEditPopup(false);
    }

    const onEdit = async (newCollectionName) => {
        try {
            let obj = {};
            obj['collection_id'] = currCollection.id;
            obj['collectionName'] = newCollectionName;
            obj['oldCollectionName'] = currCollection.collectionName;
            obj['user_name'] = userData?.preferred_username; 
            const response = await fetch(`${BASE_URL}editCollection`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj),
            });
            if (response.ok) {
                const { message } = await response.json();
                showAlert(message, "lightgreen");
                await getCollectionOptions();
                await getCollections();
            } else {
                const { message } = await response.json();
                showAlert(message, "lightcoral");
                console.error('Edit failed:', response.statusText);
            }
        } catch (error) {
            showAlert("Something wrong happen while editing collection!", "lightcoral");
        }
        console.log("EDITING ->", newCollectionName, currCollection);
        setCurrCollection(null);
        setEditPopup(false);
    }

    const hasRole = (role) => {
        return userRoles.includes(role);
    }

    useEffect(() => {
        setCanCreate(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
        setCanEdit(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
        setCanDelete(hasRole("SUPER_ADMIN"));
    }, [userRoles])

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.error && !auth.isLoading) {
          auth.signinRedirect();
        }
        if (auth.isAuthenticated) {
            // console.log("SHIVHSHIV auth", auth.user.profile);  
            setUserData(auth.user.profile);
        }
      }, [auth]);

    return (
        <>
            <Alerts />
            <ConformationPopup show={deletePopup} onCancle={onDeleteCancle} entity={'collection'} entityObj={currCollection} onDelete={onDelete} />
            <EditPopup show={editPopup} onCancle={onEditCancle} entity={'collectionName'} entityObj={currCollection} onEdit={onEdit} />
            <div className='collectionDetailsScreen'>
                <div className='filterSection'>
                    <div>
                        <span>Location :&nbsp;&nbsp;</span>
                        <SearchableSelect options={locations} selectedOption={selectedLocation} onSelect={handleSelectLocation} />
                    </div>
                    <div>
                        <span>Category :&nbsp;&nbsp;</span>
                        <SearchableSelect options={categories} selectedOption={selectedCategory} onSelect={handleSelectCategory} />
                    </div>
                    <div>
                        <span>Collection :&nbsp;&nbsp;</span>
                        <SearchableSelect options={collectionOps} selectedOption={selectedCollection} onSelect={handleSelectCollection} />
                    </div>
                    {/* <Button onClick={fliterCollection} style={{ letterSpacing: "1px" }} size="sm" variant="dark">Filter</Button> */}
                </div>
                <div className='tableSection'>
                    <Table striped bordered hover size="sm" variant="light" responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Location</th>
                                <th>Category</th>
                                <th>Collection Name</th>
                                <th>Glb Ready Products</th>
                                <th>Failed Products</th>
                                <th>Total Products</th>
                                <th>Download Images</th>
                                <th>Download Videos</th>
                                <th>More Details</th>
                                {canEdit && <th>Edit</th>}
                                {canDelete && <th>Delete</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                collections.map((collection, index) => (
                                    <tr key={index}>
                                        <td>{((currPageNo - 1) * pageSize) + (index + 1)}</td>
                                        <td>{collection.category.location.locationName}</td>
                                        <td>{collection.category.categoryName}</td>
                                        <td>{collection.collectionName}</td>
                                        <td>{collection.glbReadyProducts}</td>
                                        <td>{collection.failedProducts}</td>
                                        <td>{collection.totalProducts}</td>
                                        <td>
                                            <DownloadButton collection={collection} showAlert={showAlert} type='collection' content="image" />
                                        </td>
                                        <td>
                                            <DownloadButton collection={collection} showAlert={showAlert} type='collection' content="video" />
                                        </td>
                                        <td>
                                            <Link target="_blank" to={`/productDetailsScreen/${collection.category.location.locationName}/${collection.category.categoryName}/${collection.collectionName}`}>See Products</Link>
                                            {/* <a href="#"></a> */}
                                        </td>
                                        {canEdit && <td>
                                            <Button size="sm" variant="dark" onClick={() => { handleEdit(collection) }}>Edit</Button>
                                        </td>}
                                        {canDelete && <td>
                                            <Button size="sm" variant="danger" onClick={() => { handleDelete(collection) }}>Delete</Button>
                                        </td>}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    <div className='paginationSection'>
                        <div className='mr-2 pageNoCont'>
                            <input className='pageIn' type="number" value={currPageNo} onBlur={handleCurrPageNo} onChange={handleCurrPageNoChange} min={1} max={totalPages}
                            /> / {totalPages}
                        </div>
                        <Button style={{ width: "6rem" }} size="sm" variant="dark" onClick={fetchPrevPage} disabled={!hasPrev} >Prev</Button>
                        <Button style={{ width: "6rem" }} size="sm" variant="dark" onClick={fetchNextPage} disabled={!hasNext}>Next</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CollectionDetailsScreen;