import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import UploadScreen from "./components/UploadScreen";
import CollectionDetailsScreen from "./components/CollectionDetailsScreen";
import ProductDetailsScreen from "./components/ProductDetailsScreen";
import ProductPage from "./components/ProductPage";
import { Spinner } from 'react-bootstrap';

import { useAuth } from "react-oidc-context";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

function App() {
  const auth = useAuth();
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.error && !auth.isLoading) {
      auth.signinRedirect();
    }
    if (auth.isAuthenticated) {
      const decodedToken = jwtDecode(auth.user.access_token);
      const roles = decodedToken.realm_access.roles;
      console.log("auth", auth);
      console.log("roles",roles);
      setUserRoles(roles);
    }
  }, [auth]);

  // eslint-disable-next-line default-case
  switch (auth.activeNavigator) {
    case "signinSilent" || "signinRedirect":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      // return <div>Signing you out...</div>;
      return (
        <div style={{ position: "absolute", inset: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spinner animation="grow" />
        </div>
      );
  }

  if (auth.isLoading) {
    return (
      <div style={{ position: "absolute", inset: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spinner animation="grow" />
      </div>
    );
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }


  if (!auth.isAuthenticated && !auth.isLoading) {
    return <>Sign In Failed</>
  }

  return (
    <div className="App">
      {
        auth.isAuthenticated ? (
          <Router>
            <Header />
            <Routes>
              <Route exact path="/" element={<UploadScreen userRoles={userRoles}/>} />
              <Route
                exact
                path="/collectionDetailsScreen"
                element={<CollectionDetailsScreen userRoles={userRoles}/>}
              />
              <Route
                exact
                path="/productDetailsScreen"
                element={<ProductDetailsScreen userRoles={userRoles}/>}
              />
              <Route
                exact
                path="/productDetailsScreen/:locationName/:categoryName/:collectionName"
                element={<ProductDetailsScreen userRoles={userRoles}/>}
              />
              <Route exact path="/productPage/:styleNo" element={<ProductPage userRoles={userRoles}/>} />
            </Routes>
          </Router>
        ) :
          (
            <div style={{ position: "absolute", inset: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spinner animation="grow" />
            </div>
          )
      }
    </div>
  );
}

export default App;