import React, { useState, useEffect } from 'react';
import './css/ProductDetailsScreen.css';
import SearchableSelect from './SearchableSelect.jsx';
import { Table, Button, Form, Spinner } from 'react-bootstrap';
import { BASE_URL, RENDER_BASE_URL } from '../lib/common.js';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DownloadButton from './DownloadButton.jsx';
import ConformationPopup from './ConformationPopup.jsx';

import { useAuth } from "react-oidc-context";

const ProductDetailsScreen = ({ userRoles }) => {

    const auth = useAuth();
    const [userData, setUserData] = useState(null);

    const [canCreate, setCanCreate] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    const [deletePopup, setDeletePopup] = useState(false);
    const [currProduct, setCurrProduct] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const { locationName, categoryName, collectionName } = useParams();

    const [locations, setLocations] = useState([]);
    const [categories, setCategories] = useState([]);
    const [collections, setCollections] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCollection, setSelectedCollection] = useState('');
    const [styleNo, setStyleNo] = useState('');
    const handleStyleNo = (e) => {
        const number = e.target.value;
        setStyleNo(number);
    };

    const pageSize = 10;
    const [currPageNo, setCurrPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }

    const handleSelectLocation = (option) => {
        setSelectedLocation(option);
    };

    const handleSelectCategory = (option) => {
        setSelectedCategory(option);
    };

    const handleSelectCollection = (option) => {
        setSelectedCollection(option);
    };

    const fliterCollection = () => {
        console.log("Filterd!");
    }

    const fetchNextPage = () => {
        setCurrPageNo(currPageNo + 1);
        // setHasNext((currPageNo + 1 < totalPages) ? true : false);
        // setHasPrev((currPageNo + 1 > 1) ? true : false);
    }

    const fetchPrevPage = () => {
        setCurrPageNo(currPageNo - 1);
        // setHasNext((currPageNo - 1 < totalPages) ? true : false);
        // setHasPrev((currPageNo - 1 > 1) ? true : false);
    }

    const handleCurrPageNoChange = (e) => {
        const newPageNo = parseInt(e.target.value, 10);
        setCurrPageNo(newPageNo);
    };

    const handleCurrPageNo = (e) => {
        let newPageNo = parseInt(e.target.value, 10);
        newPageNo = (isNaN(newPageNo) || newPageNo < 1 || newPageNo > totalPages) ? 1 : newPageNo;
        setCurrPageNo(newPageNo);
    };

    const getLocations = async () => {
        try {
            const response = await fetch(`${BASE_URL}getLocations`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let locationOptions = [];
            for (let location of data) {
                locationOptions.push(location.locationName);
            }
            setLocations(locationOptions);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const getCategories = async () => {
        try {
            const response = await fetch(`${BASE_URL}getCategories?location_name=${selectedLocation}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let categoryOptions = [];
            for (let category of data) {
                categoryOptions.push(category.categoryName);
            }
            setCategories(categoryOptions);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const getCollections = async () => {
        try {
            const response = await fetch(`${BASE_URL}getCollectionOptions?location_name=${selectedLocation}&&category_name=${selectedCategory}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let collectionOptions = [];
            for (let collection of data) {
                collectionOptions.push(collection.collectionName);
            }
            setCollections(collectionOptions);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const getProducts = async () => {
        try {
            const response = await fetch(`${BASE_URL}getProducts?location_name=${selectedLocation}&&category_name=${selectedCategory}&&collection_name=${selectedCollection}&&style_no=${styleNo}&&page=${currPageNo}&&pageSize=${pageSize}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const { totalPages, hasNext, hasPrev, products } = await response.json();
            // let collectionOptions = [];
            // for (let collection of data) {
            //     collectionOptions.push(collection.collectionName);
            // }
            setProducts(products);
            setTotalPages(totalPages);
            setHasNext(hasNext);
            setHasPrev(hasPrev);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const getAllData = async () => {
        await getLocations();
        await getCategories();
        await getCollections();
        await getProducts();

        if (categoryName && locationName && collectionName) {
            setSelectedLocation(locationName);
            setSelectedCategory(categoryName);
            setTimeout(() => {
                setSelectedCollection(collectionName);
            }, 100);
        }
    }

    useEffect(() => {
        getAllData();
    }, [])

    useEffect(() => {
        if (selectedLocation == '' || selectedLocation) {
            setCurrPageNo(1);
            setSelectedCategory('');
            getCategories();
            getCollections();
            getProducts();
        }
    }, [selectedLocation])

    useEffect(() => {
        if (selectedCategory == '' || selectedCategory) {
            setCurrPageNo(1);
            setSelectedCollection('');
            getCollections();
            getProducts();
        }
    }, [selectedCategory])

    useEffect(() => {
        if (selectedCollection == '' || selectedCollection) {
            setCurrPageNo(1);
            getProducts();
        }
    }, [selectedCollection])

    useEffect(() => {
        if (currPageNo) {
            getProducts();
        }
    }, [currPageNo])

    // const downloadImages = async (collectionName, styleNo) => {
    //     setIsLoading(true);
    //     try {
    //         const response = await fetch(`${BASE_URL}productDownload?collection_name=${collectionName}&style_no=${styleNo}`);
    //         if (!response.ok) {
    //             throw new Error('response was not ok');
    //         }
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = `${styleNo}.zip`; 
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //         showAlert("Images downloaded successfully!", "lightgreen");
    //     } catch (error) {
    //         showAlert("Error while downloading images!", "lightcoral");
    //     }
    //     setIsLoading(false);
    // }

    const handleDelete = async (product) => {
        setCurrProduct(product);
        setDeletePopup(true);
    }

    const onDeleteCancle = async () => {
        setCurrProduct(null);
        setDeletePopup(false);
    }

    const onDelete = async () => {
        try {
            let obj = { "id": currProduct.id, "collection_name": currProduct.collection.collectionName, "style_no": currProduct.productName, "user_name": userData?.preferred_username };
            const response = await fetch(`${BASE_URL}deleteProduct`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj),
            });
            if (response.ok) {
                const { message } = await response.json();
                showAlert(message, "lightgreen");
                getProducts();
            } else {
                const { message } = await response.json();
                showAlert(message, "lightcoral");
                console.error('Delete failed:', response.statusText);
            }
        } catch (error) {
            showAlert("Something wrong happen while deleting product!", "lightcoral");
        }
        console.log("DELETING ->", currProduct);
        setCurrProduct(null);
        setDeletePopup(false);
    }

    const hasRole = (role) => {
        return userRoles.includes(role);
    }

    useEffect(() => {
        setCanCreate(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
        setCanEdit(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
        setCanDelete(hasRole("SUPER_ADMIN"));
    }, [userRoles])

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.error && !auth.isLoading) {
          auth.signinRedirect();
        }
        if (auth.isAuthenticated) {
            // console.log("SHIVHSHIV auth", auth.user.profile);  
            setUserData(auth.user.profile);
        }
      }, [auth]);

    return (
        <>
            <Alerts />
            <ConformationPopup show={deletePopup} onCancle={onDeleteCancle} entity={'product'} entityObj={currProduct} onDelete={onDelete} />
            <div className='productDetailsScreen'>
                <div className='filterSection'>
                    <div>
                        <span>Location :&nbsp;&nbsp;</span>
                        <SearchableSelect options={locations} selectedOption={selectedLocation} onSelect={handleSelectLocation} />
                    </div>
                    <div>
                        <span>Category :&nbsp;&nbsp;</span>
                        <SearchableSelect options={categories} selectedOption={selectedCategory} onSelect={handleSelectCategory} />
                    </div>
                    <div>
                        <span>Collection :&nbsp;&nbsp;</span>
                        <SearchableSelect options={collections} selectedOption={selectedCollection} onSelect={handleSelectCollection} />
                    </div>
                    <div>
                        <span>Style No :&nbsp;&nbsp;</span>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Enter Style No."
                            value={styleNo}
                            onChange={handleStyleNo}
                            onBlur={() => { setCurrPageNo(1); getProducts(); }}
                        />
                    </div>
                    {/* <Button onClick={fliterCollection} style={{ letterSpacing: "1px" }} size="sm" variant="dark">Filter</Button> */}
                </div>
                <div className='tableSection'>
                    <div style={{textAlign:"left", marginBottom:"5px"}}> <span style={{fontWeight:"bold"}}>Note:</span> The product will be GLB-ready 5 minutes after uploading.</div>
                    <Table striped bordered hover size="sm" variant="light" responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Location</th>
                                <th>Category</th>
                                <th>Collection Name</th>
                                <th>Style No</th>
                                <th>Glb Ready Status</th>
                                <th>View</th>
                                <th>Image Count</th>
                                <th>Video Count</th>
                                <th>Download Images</th>
                                <th>Download Videos</th>
                                <th>More Details</th>
                                {canDelete && <th>Delete</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                products.map((product, index) => (
                                    <tr key={index}>
                                        <td>{((currPageNo - 1) * pageSize) + (index + 1)}</td>
                                        <td>{product.collection.category.location.locationName}</td>
                                        <td>{product.collection.category.categoryName}</td>
                                        <td>{product.collection.collectionName}</td>
                                        <td>{product.productName}</td>
                                        <td>{product.glbReadyStatus}</td>
                                        <td>
                                            <Button size="sm" variant="dark" disabled={!(product.glbReadyStatus && product.glbReadyStatus == "Ready")}>
                                                <Link style={{ color: "white", textDecoration: "none" }} target="_blank" to={`${RENDER_BASE_URL}/${product.productName}`}>
                                                    View
                                                </Link>
                                            </Button>
                                        </td>
                                        <td>{product.imageCount}</td>
                                        <td>{product.videoCount}</td>
                                        <td>
                                            <DownloadButton product={product} showAlert={showAlert} type='product' content="image" />
                                        </td>
                                        <td>
                                            <DownloadButton product={product} showAlert={showAlert} type='product'content="video" />
                                        </td>
                                        <td>
                                            <Link target="_blank" to={`/productPage/${product.productName}`}>See More</Link>
                                            {/* <a href="#"></a> */}
                                        </td>
                                        {canDelete && <td>
                                            <Button size="sm" variant="danger" onClick={() => { handleDelete(product) }}>Delete</Button>
                                        </td>}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    <div className='paginationSection'>
                        <div className='mr-2 pageNoCont'>
                            <input className='pageIn' type="number" value={currPageNo} onBlur={handleCurrPageNo} onChange={handleCurrPageNoChange} min={1} max={totalPages}
                            /> / {totalPages}
                        </div>
                        <Button style={{ width: "6rem" }} className="ml-2 mr-2" size="sm" variant="dark" onClick={fetchPrevPage} disabled={!hasPrev}>
                            Prev
                        </Button>
                        <Button style={{ width: "6rem" }} className="ml-2" size="sm" variant="dark" onClick={fetchNextPage} disabled={!hasNext}>
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDetailsScreen;