import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Form } from 'react-bootstrap';

const ConformationPopup = ({ show, onCancle, entity, entityObj, onDelete }) => {

    const handleCancle = () => {
        onCancle();
    };

    const handleDelete = () => {
        onDelete();
    };


    return (
        <div>
            <Modal show={show} onHide={handleCancle} centered>
                <Modal.Header closeButton style={{ fontFamily: "Nunito" }}>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ fontFamily: "Nunito" }}>
                        Are you sure, you want to delete {entity} {(entity=="collection") ? entityObj?.collectionName : entityObj?.productName}?
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "center" }}>
                    <Button style={{ fontFamily: "Nunito" }} variant="danger" onClick={handleDelete}>
                        Yes
                    </Button>
                    <Button style={{ fontFamily: "Nunito" }} variant="secondary" onClick={handleCancle}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ConformationPopup;