import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Form } from 'react-bootstrap';
import SearchableSelect from './SearchableSelect';

const EditPopup2 = ({ show, onCancle, entity, entityOptions, onEdit }) => {

    const [entityValue, setEntityValue] = useState('');
    const [currEntityValue, setCurrEntityValue] = useState('');

    const handleEntityValueChange = (e) => {
        setEntityValue(e.target.value);
    }

    const handleCancle = () => {
        onCancle();
        setEntityValue('');
        setCurrEntityValue('');
    };

    const handleEdit = () => {
        onEdit(currEntityValue,entityValue);
        setEntityValue('');
        setCurrEntityValue('');
    };

    const handleSelectEntityValue = (option) =>{
        setCurrEntityValue(option);
    }


    return (
        <div>
            <Modal show={show} onHide={handleCancle} centered>
                <Modal.Header closeButton style={{ fontFamily: "Nunito" }}>
                    <Modal.Title>Edit {entity}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table style={{ width: "100%", fontFamily: "Nunito" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "20%" }}>{entity}</td>
                                <td >:</td>
                                <td style={{ width: "95%", float: "right" }}>
                                    <SearchableSelect options={entityOptions} selectedOption={currEntityValue} onSelect={handleSelectEntityValue} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "20%", textWrap: "nowrap" }}>New {entity}</td>
                                <td >:</td>
                                <td style={{ width: "95%", float: "right" }}>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        placeholder={`Enter New ${entity}`}
                                        value={entityValue}
                                        onChange={handleEntityValueChange}
                                        disabled={!currEntityValue}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "center" }}>
                    <Button disabled={entityValue == ''} style={{ fontFamily: "Nunito" }} variant="secondary" onClick={handleEdit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditPopup2;