import React, { useEffect, useState } from 'react';
import './css/ProductPage.css';
import { useParams } from 'react-router-dom';
import { Table, Button, Form, Row, Col, Carousel, Container, Spinner } from 'react-bootstrap';
import { BASE_URL } from '../lib/common.js';
import CollectionDetailsScreen from './CollectionDetailsScreen';
import DownloadSvg from '../assets/icons/download.svg';

import { useAuth } from "react-oidc-context";

const ProductPage = () => {

    const auth = useAuth();
    const [userData, setUserData] = useState(null);

    var timingOptions = {
        timeZone: 'Asia/Kolkata', // IST timezone
        timeZoneName: 'short',
        hour12: false // 24-hour format
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isVidLoading, setIsVidLoading] = useState(false);

    const [isReplacing, setIsReplacing] = useState(false);
    const [selectedColor, setSelectedColor] = useState('');

    const { styleNo } = useParams();
    const [products, setProdcuts] = useState([]);
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);

    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState(null);

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }

    const getImages = async (collection_name, product_name) => {
        try {
            const response = await fetch(`${BASE_URL}getImages?collection_name=${collection_name}&style_no=${product_name}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setImages(data);
        } catch (error) {
            console.error('Error during fetch:', error);
            // throw error;
        }
    }

    const getVideos = async (collection_name, product_name) => {
        try {
            const response = await fetch(`${BASE_URL}getVideos?collection_name=${collection_name}&style_no=${product_name}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setVideos(data);
        } catch (error) {
            console.error('Error during fetch:', error);
        }
    }

    const getProducts = async () => {
        try {
            const response = await fetch(`${BASE_URL}getProducts?style_no=${styleNo}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const { products } = await response.json();
            // let collectionOptions = [];
            // for (let collection of data) {
            //     collectionOptions.push(collection.collectionName);
            // }
            setProdcuts(products);
        } catch (error) {
            console.error('Error during fetch:', error);
            // throw error;
        }
    }

    useEffect(() => {
        if (styleNo) {
            getProducts();
        }
    }, [styleNo])

    useEffect(() => {
        if (products.length > 0) {
            const product = products[0];
            const collectionName = product.collection.collectionName;
            const productName = product.productName;
            getImages(collectionName, productName);
            getVideos(collectionName, productName);
        }
    }, [products])

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name.toLowerCase();
            // if (!fileName.endsWith('.stl') && !fileName.endsWith('.glb')) {
            if (!fileName.endsWith('.fbx')) {
                showAlert('Please select a .fbx file.', 'lightcoral');
                return;
            }
            setFile(file);
            // (fileName.endsWith('.stl')) ? setFileType("stl") : setFileType("glb");
            setFileType("fbx");
            if (file.size > 200 * 1024 * 1024) {
                alert('File size exceeds 200MB limit.');
                return;
            }
        }
    };

    const replaceModel = async (product) => {
        setIsReplacing(true);
        const location = product.collection.category.location.locationName;
        const category = product.collection.category.categoryName;
        const collection = product.collection.collectionName;
        const styleNo = product.productName;

        if (file == null) {
            showAlert("Please Choose a file!", "lightcoral");
            setIsReplacing(false);
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('location_name', location);
        formData.append('category_name', category);
        formData.append('collection_name', collection);
        formData.append('style_no', styleNo);
        formData.append('fileType', file.type);
        formData.append('user_name', userData?.preferred_username);
        // console.log(file);
        try {
            const response = await fetch(`${BASE_URL}replaceFile`, {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                const { message } = await response.json();
                showAlert(message, "lightgreen");
            } else {
                const { message } = await response.json();
                showAlert(message, "lightcoral");
            }
        } catch (error) {
            showAlert("Something wrong happen while replacing the file!", "lightcoral");
            console.error('Something wrong happen while replacing the file! ', error);
        }

        setFile(null);
        setFileType(null);
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.value = '';
        }
        setIsReplacing(false);
    };

    const downloadImages = async (collectionName, styleNo) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}productDownload?collection_name=${collectionName}&style_no=${styleNo}`);
            if (!response.ok) {
                throw new Error('response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${styleNo}.zip`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            showAlert("Images downloaded successfully!", "lightgreen");
        } catch (error) {
            showAlert("Error while downloading images!", "lightcoral");
        }
        setIsLoading(false);
    }

    const downloadVideos = async (collectionName, styleNo) => {
        setIsVidLoading(true);
        try {
            const fetchUrl = `${BASE_URL}productDownloadVideo?collection_name=${collectionName}&style_no=${styleNo}`;
            const response = await fetch(fetchUrl);
            if (!response.ok) {
                throw new Error('response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${styleNo}.zip`; 
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            showAlert("Vedios downloaded successfully!", "lightgreen");
        } catch (error) {
            showAlert("Error while downloading videos!", "lightcoral");
        }
        setIsVidLoading(false);
    }

    const handleDownload = (imgUrl) => {
        if (imgUrl) {
            const downloadLink = document.createElement('a');
            downloadLink.href = imgUrl;
            downloadLink.target = '_blank';

            const urlParts = imgUrl.split('=');
            const fileName = urlParts[urlParts.length - 1];
            downloadLink.download = fileName;
            downloadLink.setAttribute('download', fileName);

            downloadLink.click();
        } else {
            console.log('Invalid image URL');
            showAlert('Invalid image URL', 'red', 4000);
        }
    };

    const handleColorChange = (color) => {
        setSelectedColor(color);
    }

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.error && !auth.isLoading) {
            auth.signinRedirect();
        }
        if (auth.isAuthenticated) {
            // console.log("SHIVHSHIV auth", auth.user.profile);  
            setUserData(auth.user.profile);
        }
    }, [auth]);

    return (
        <>
            <Alerts />
            <div className='productPageCont'>
                {products.length > 0 && (
                    <>
                        <div className='section1'>
                            <div className='imageCont'>
                                {
                                    // (!products[0].imageCount || products[0].imageCount <= 0) ?
                                    (images.length <= 0 && videos.length <= 0) ?
                                        (<>
                                            <div className="m-auto imgCont">
                                                {/* <img
                                                className='imgBox'
                                                src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOFx557XPIXXmnhk7joe2Pq2uQhb1iCJ688RgQZzH5ZA&s`}
                                                alt="sbv"
                                            /> */}
                                                <span>Image Or Video Not Generated Yet!</span>
                                            </div>
                                        </>

                                        ) :
                                        (
                                            (images.filter(imgSrc => imgSrc.split('=')[3].split('-')[imgSrc.split('=')[3].split('-').length - 3].startsWith(selectedColor)).length > 0) ||
                                                (videos.filter(vidSrc => vidSrc.split('=')[3].split('-')[vidSrc.split('=')[3].split('-').length - 3].startsWith(selectedColor)).length > 0)
                                                ? (
                                                    <Carousel className="carouselCont">
                                                        {
                                                            images.filter(imgSrc => imgSrc.split('=')[3].split('-')[imgSrc.split('=')[3].split('-').length - 3].startsWith(selectedColor))
                                                                .map((imgSrc, idx) => (
                                                                    <Carousel.Item key={idx} className="carouselItm">
                                                                        <div style={{ position: "relative" }}>
                                                                            <span className='imgNameSpan' >{imgSrc.split('=')[3]}</span>
                                                                            {/* <span className='imgNameSpan' >{imgSrc.split('=')[3].split('-')[imgSrc.split('=')[3].split('-').length - 2]}</span> */}
                                                                            <img src={DownloadSvg} alt="download" className='downloadBtn' onClick={() => handleDownload(imgSrc)} />
                                                                        </div>
                                                                        <div className="m-auto imgCont">
                                                                            <img
                                                                                className='imgBox'
                                                                                src={imgSrc}
                                                                                alt="sbv"
                                                                            />
                                                                        </div>
                                                                    </Carousel.Item>
                                                                ))}
                                                        {
                                                            videos.filter(vidSrc => vidSrc.split('=')[3].split('-')[vidSrc.split('=')[3].split('-').length - 3].startsWith(selectedColor))
                                                                .map((vidSrc, idx) => (
                                                                    <Carousel.Item key={`v${idx}`} className="carouselItm">
                                                                        <div style={{ position: "relative" }}>
                                                                            <span className='imgNameSpan' >{vidSrc.split('=')[3]}</span>
                                                                            <img src={DownloadSvg} alt="download" className='downloadBtn' onClick={() => handleDownload(vidSrc)} />
                                                                        </div>
                                                                        <div className="m-auto imgCont">
                                                                            <video className='vidBox' controls>
                                                                                <source src={vidSrc} type="video/webm" />
                                                                            </video>
                                                                        </div>
                                                                    </Carousel.Item>
                                                                ))}
                                                    </Carousel>
                                                ) : (
                                                    <div className="m-auto imgCont">
                                                        <span>Image or Video not found for this color filter!</span>
                                                    </div>
                                                )


                                        )
                                }
                                <div className='d-flex justify-content-between m-1'>

                                    <div className='colorOptions m-1'>
                                        <div title="All" className={`colorOp ${(selectedColor === '') && 'selected'}`} style={{}} onClick={() => handleColorChange('')} >All</div>
                                        <div title="Platinum" className={`colorOp ${(selectedColor === 'P') && 'selected'}`} style={{ backgroundColor: "#C3C3C3" }} onClick={() => handleColorChange('P')} >P</div>
                                        <div title="Yellow Gold" className={`colorOp ${(selectedColor === 'YG') && 'selected'}`} style={{ backgroundColor: "#ebcf9a" }} onClick={() => handleColorChange('YG')} >YG</div>
                                        <div title="White Gold" className={`colorOp ${(selectedColor === 'WG') && 'selected'}`} style={{ backgroundColor: "#d8d8d8" }} onClick={() => handleColorChange('WG')} >WG</div>
                                        <div title="Rose Gold" className={`colorOp ${(selectedColor === 'RG') && 'selected'}`} style={{ backgroundColor: "#ebc4af" }} onClick={() => handleColorChange('RG')} >RG</div>
                                        <div title="Silver" className={`colorOp ${(selectedColor === 'S') && 'selected'}`} style={{ backgroundColor: "#c8c8c8" }} onClick={() => handleColorChange('S')} >S</div>
                                    </div>

                                    {/* <Button disabled={!products[0].imageCount || products[0].imageCount <= 0} className="m-auto" size="sm" variant="dark"> */}
                                    <Button size="sm" variant="dark" disabled={(images.length <= 0)} onClick={!isLoading ? () => { downloadImages(products[0].collection.collectionName, products[0].productName); } : null}>
                                        {
                                            isLoading ?
                                                (<Spinner animation="border" size="sm" />)
                                                :
                                                (
                                                    <span>Download All Images</span>
                                                )
                                        }
                                    </Button>
                                    <Button size="sm" variant="dark" disabled={(videos.length <= 0)} onClick={!isVidLoading ? () => { downloadVideos(products[0].collection.collectionName, products[0].productName); } : null}>
                                        {
                                            isVidLoading ?
                                                (<Spinner animation="border" size="sm" />)
                                                :
                                                (
                                                    <span>Download All Video</span>
                                                )
                                        }
                                    </Button>
                                </div>
                            </div>
                            <div className='productCont'>
                                <Table striped="columns" bordered hover size="sm" variant="light" responsive style={{ marginBottom: "unset" }}>
                                    <tbody>
                                        <tr>
                                            <td>Collection Name</td>
                                            <td>{products[0].collection.collectionName}</td>
                                        </tr>
                                        <tr>
                                            <td>Style No</td>
                                            <td>{products[0].productName}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className='section2'>
                            <Table striped="columns" bordered hover size="sm" variant="light" responsive>
                                <tbody>
                                    <tr>
                                        <td>Location Name</td>
                                        <td>{products[0].collection.category.location.locationName}</td>
                                    </tr>
                                    <tr>
                                        <td>Category Name</td>
                                        <td>{products[0].collection.category.categoryName}</td>
                                    </tr>
                                    {/* <tr>
                                    <td>Collection Name</td>
                                    <td>{products[0].collection.collectionName}</td>
                                </tr>
                                <tr>
                                    <td>Product Name</td>
                                    <td>{products[0].productName}</td>
                                </tr> */}
                                    <tr>
                                        <td>Created At</td>
                                        {/* <td>{products[0].createdAt}</td> */}
                                        <td>{products[0].createdAt ? (new Date(products[0].createdAt)).toLocaleString('en-US', timingOptions).replace(" GMT+5:30", " IST") : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Created By</td>
                                        <td>{products[0].createdBy}</td>
                                    </tr>
                                    <tr>
                                        <td>Filename</td>
                                        <td>{products[0].fbxFileName}</td>
                                    </tr>
                                    <tr>
                                        <td>Last uploaded timestamp (fbx)</td>
                                        <td>{products[0].fbxUploadTimestamp ? (new Date(parseInt(products[0].fbxUploadTimestamp) * 1000)).toLocaleString('en-US', timingOptions).replace(" GMT+5:30", " IST") : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>fbx file size</td>
                                        <td>{products[0].fbxFileSize ? `${parseFloat(products[0].fbxFileSize).toFixed(2)} MB` : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>glb conversion</td>
                                        <td style={{ backgroundColor: (products[0].isProcessed) ? "lightgreen" : "", boxShadow: (products[0].isProcessed) ? "unset" : "" }}>{products[0].isProcessed ? "Done" : "Pending"}</td>
                                    </tr>
                                    <tr>
                                        <td>glb conversion timestamp</td>
                                        {/* <td>{products[0].glbGeneratedTimestamp}</td> */}
                                        {/* <td>{(new Date(parseInt(products[0].glbGeneratedTimestamp)*1000)).toISOString()}</td> */}
                                        <td>{products[0].glbGeneratedTimestamp ? (new Date(parseInt(products[0].glbGeneratedTimestamp) * 1000)).toLocaleString('en-US', timingOptions).replace(" GMT+5:30", " IST") : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>glb file size</td>
                                        <td>{products[0].glbSize ? `${parseFloat(products[0].glbSize).toFixed(2)} MB` : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Number of diamonds</td>
                                        <td>{products[0].diamondCount}</td>
                                    </tr>
                                    <tr>
                                        <td>glb ready status</td>
                                        <td style={{ backgroundColor: (products[0].glbReadyStatus == "Ready") ? "lightgreen" : ((products[0].glbReadyStatus == "Failed") ? "lightcoral" : ""), boxShadow: (products[0].glbReadyStatus == "Ready" || products[0].glbReadyStatus == "Failed") ? "unset" : "" }}>{products[0].glbReadyStatus}</td>
                                    </tr>
                                    <tr>
                                        <td>Image count</td>
                                        <td>{products[0].imageCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Video count</td>
                                        <td>{products[0].videoCount}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <Form.Control
                                    id="fileInput"
                                    size="sm"
                                    type="file"
                                    accept=".fbx"
                                    // accept=".stl"
                                    // accept=".stl,.glb"
                                    onChange={handleFileChange}
                                />
                                <Button disabled={isReplacing} onClick={!isReplacing ? () => replaceModel(products[0]) : null} size="sm" variant="dark" >
                                    {isReplacing ? <Spinner animation="border" size="sm" /> : 'Replace'}
                                </Button>
                            </div>
                        </div>
                    </>
                )
                }
            </div >
        </>
    )
}

export default ProductPage;