import React from 'react'
import { useState } from 'react';
import './css/ProductDetailsScreen.css';
import { Table, Button, Form, Spinner } from 'react-bootstrap';
import { BASE_URL } from '../lib/common.js';

export default function DownloadButton(props) {
    const [isLoading, setIsLoading] = useState(false);

    const downloadImages = async (collectionName, styleNo) => {
        console.log('for', props.type);
        setIsLoading(true);
        try {
            const fetchUrl = props.type === 'product' ? `${BASE_URL}productDownload?collection_name=${collectionName}&style_no=${styleNo}` : `${BASE_URL}collectionDownload?collection_name=${collectionName}`
            const response = await fetch(fetchUrl);
            if (!response.ok) {
                throw new Error('response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = props.type === 'product' ? `${styleNo}.zip` : `${collectionName}.zip`; 
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            props.showAlert("Images downloaded successfully!", "lightgreen");
        } catch (error) {
            props.showAlert("Error while downloading images!", "lightcoral");
        }
        setIsLoading(false);
    }

    const downloadVideos = async (collectionName, styleNo) => {
        console.log('for', props.type);
        setIsLoading(true);
        try {
            const fetchUrl = props.type === 'product' ? `${BASE_URL}productDownloadVideo?collection_name=${collectionName}&style_no=${styleNo}` : `${BASE_URL}collectionDownloadVideo?collection_name=${collectionName}`
            const response = await fetch(fetchUrl);
            if (!response.ok) {
                throw new Error('response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = props.type === 'product' ? `${styleNo}.zip` : `${collectionName}.zip`; 
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            props.showAlert("Vedios downloaded successfully!", "lightgreen");
        } catch (error) {
            props.showAlert("Error while downloading videos!", "lightcoral");
        }
        setIsLoading(false);
    }

  return (
    <>
    {props.type === 'product' ? <Button size="sm" variant="dark" disabled={(props.content === "image") ? !(props.product.imageCount && props.product.imageCount > 0) : !(props.product.videoCount && props.product.videoCount > 0)} 
            onClick={!isLoading ? () => { 
               (props.content === "image") ?  downloadImages(props.product.collection.collectionName, props.product.productName) : downloadVideos(props.product.collection.collectionName, props.product.productName); 
                } : null}>
        {
            isLoading ? 
            (<Spinner animation="border" size="sm"/>)
            : 
            (
                <span>Download</span>
            )
        }
    </Button> : 
    <Button size="sm" variant="dark" onClick={!isLoading ? () => { (props.content == "image") ?  downloadImages(props.collection.collectionName) : downloadVideos(props.collection.collectionName); } : null}>
            {
                isLoading ? 
                (<Spinner animation="border" size="sm"/>)
                : 
                (
                    <span>Download</span>
                )
            }
        </Button>
    }
    </>
  )
}
