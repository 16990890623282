import React, { useEffect, useState } from 'react';
import './css/UploadScreen.css';
import { Button, Form, Spinner } from 'react-bootstrap';
import SearchableSelect from './SearchableSelect';
import CreateEntity from './CreateEntity';
import { BASE_URL } from '../lib/common.js';
import EditPopup2 from './EditPopup2.jsx';

import { useAuth } from "react-oidc-context";

const UploadScreen = ({ userRoles }) => {

    const auth = useAuth();
    const [userData, setUserData] = useState(null);

    const [canCreate, setCanCreate] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [categories, setCategories] = useState([]);
    const [collections, setCollections] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCollection, setSelectedCollection] = useState('');
    const [styleNo, setStyleNo] = useState('');
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState(null);

    const [entity, setEntity] = useState(null);
    const [show, setShow] = useState(false);
    const [showEditPopup, setEditPopupShow] = useState(false);

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }

    const handleSelectLocation = (option) => {
        setSelectedLocation(option);
    };

    const handleSelectCategory = (option) => {
        setSelectedCategory(option);
    };

    const handleSelectCollection = (option) => {
        setSelectedCollection(option);
    };

    const handleStyleNo = (e) => {
        const number = e.target.value;
        setStyleNo(number);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name.toLowerCase();
            // if (!fileName.endsWith('.stl') && !fileName.endsWith('.glb')) {
            if (!fileName.endsWith('.fbx')) {
                showAlert('Please select a .fbx file.', 'lightcoral');
                return;
            }
            setFile(file);
            // (fileName.endsWith('.stl')) ? setFileType("stl") : setFileType("glb");
            setFileType("fbx");
            if (file.size > 200 * 1024 * 1024) {
                alert('File size exceeds 200MB limit.');
                return;
            }
        }
    };

    const uploadModel = async () => {
        setIsLoading(true);
        if (selectedLocation == null || selectedLocation == '') {
            showAlert("Please select applicable Location!", "lightcoral");
            setIsLoading(false);
            return;
        }
        if (selectedCategory == null || selectedCategory == '') {
            showAlert("Please select applicable Category or create new one!", "lightcoral");
            setIsLoading(false);
            return;
        }
        if (selectedCollection == null || selectedCollection == '') {
            showAlert("Please select applicable Collection Name or create new one!", "lightcoral");
            setIsLoading(false);
            return;
        }
        if (styleNo == '') {
            showAlert("Please enter Style No!", "lightcoral");
            setIsLoading(false);
            return;
        }
        if (file == null) {
            showAlert("Please Choose a file!", "lightcoral");
            setIsLoading(false);
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('location_name', selectedLocation);
        formData.append('category_name', selectedCategory);
        formData.append('collection_name', selectedCollection);
        formData.append('style_no', styleNo);
        formData.append('fileType', file.type);
        formData.append('user_name', userData?.preferred_username);
        // console.log(file);
        try {
            const response = await fetch(`${BASE_URL}uploadFile`, {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                const { message } = await response.json();
                showAlert(message, "lightgreen");
            } else {
                const { message } = await response.json();
                showAlert(message, "lightcoral");
                console.error('Upload failed:', response.statusText);
            }
        } catch (error) {
            showAlert("Something wrong happen while uploading the file!", "lightcoral");
            console.error('Something wrong happen while uploading the file!', error);
        }

        setIsLoading(false);
        // setSelectedLocation('');
        // setSelectedCategory('');
        // setSelectedCollection('');
        setStyleNo('');
        setFile(null);
        setFileType(null);
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const createEntity = (entity) => {
        setEntity(entity);
        setShow(true);
    }

    const getLocations = async () => {
        try {
            const response = await fetch(`${BASE_URL}getLocations`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let locationOptions = [];
            for (let location of data) {
                locationOptions.push(location.locationName);
            }
            setLocations(locationOptions);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const getCategories = async () => {
        try {
            const response = await fetch(`${BASE_URL}getCategories?location_name=${selectedLocation}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let categoryOptions = [];
            for (let category of data) {
                categoryOptions.push(category.categoryName);
            }
            setCategories(categoryOptions);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    const getCollections = async () => {
        try {
            const response = await fetch(`${BASE_URL}getCollectionOptions?location_name=${selectedLocation}&&category_name=${selectedCategory}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let collectionOptions = [];
            for (let collection of data) {
                collectionOptions.push(collection.collectionName);
            }
            setCollections(collectionOptions);
        } catch (error) {
            console.error('Error during fetch:', error);
            throw error;
        }
    }

    useEffect(() => {
        getLocations();
        // getCategories();
        // getCollections();
    }, [])

    useEffect(() => {
        // if (selectedLocation && selectedLocation != '') {
        if (selectedLocation == '' || selectedLocation) {
            setSelectedCategory('');
            getCategories();
        }
    }, [selectedLocation])

    useEffect(() => {
        // if (selectedCategory && selectedCategory != '') {
        if (selectedCategory == '' || selectedCategory) {
            setSelectedCollection('');
            getCollections();
        }
    }, [selectedCategory])

    const hasRole = (role) => {
        return userRoles.includes(role);
    }

    useEffect(() => {
        setCanCreate(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
        setCanEdit(hasRole("SUPER_ADMIN") || hasRole('ADMIN'));
    }, [userRoles])


    const editEntity = (entity) => {
        setEntity(entity);
        setEditPopupShow(true);
    }

    const onEditCancle = async () => {
        setEditPopupShow(false);
    }

    const onEdit = async (oldEntityValue, newEntityValue) => {
        try {
            let obj = {};
            obj['user_name'] = userData?.preferred_username;
            if (entity == "Location") {
                obj["oldLocationName"] = oldEntityValue;
                obj["locationName"] = newEntityValue;
            } else if (entity == "Category") {
                obj["locationName"] = selectedLocation;
                obj["oldCategoryName"] = oldEntityValue;
                obj["categoryName"] = newEntityValue;
            }

            const response = await fetch(`${BASE_URL}edit${entity}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj),
            });
            if (response.ok) {
                const { message } = await response.json();
                showAlert(message, "lightgreen");
                if (entity == "Location") {
                    await getLocations();
                    setSelectedLocation('');
                }

                if (entity == "Category") {
                    await getCategories();
                    setSelectedCategory('');
                }

            } else {
                const { message } = await response.json();
                showAlert(message, "lightcoral");
                console.error('Edit failed:', response.statusText);
            }
        } catch (error) {
            showAlert(`Something wrong happen while editing ${entity}!`, "lightcoral");
        }
        console.log("EDITING ->", oldEntityValue, newEntityValue);
        setEditPopupShow(false);
    }

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.error && !auth.isLoading) {
            auth.signinRedirect();
        }
        if (auth.isAuthenticated) {
            // console.log("SHIVHSHIV auth", auth.user.profile);  
            setUserData(auth.user.profile);
        }
    }, [auth]);

    return (
        <>
            <CreateEntity entity={entity} show={show} setShow={setShow} setEntity={setEntity}
                selectedLocation={selectedLocation} selectedCategory={selectedCategory}
                getLocations={getLocations} getCategories={getCategories} getCollections={getCollections}
            />
            <EditPopup2 show={showEditPopup} onCancle={onEditCancle} entity={entity} entityOptions={entity == "Location" ? locations : categories} onEdit={onEdit} />
            <Alerts />
            <div className='uploadScreenCont'>
                <div className='uploadFormCont'>
                    <table>
                        <tbody>
                            <tr>
                                <td>Location</td>
                                <td>:</td>
                                <td>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: (canCreate) ? "65%" : "100%" }}>
                                            <SearchableSelect options={locations} selectedOption={selectedLocation} onSelect={handleSelectLocation} />
                                        </div>
                                        {canCreate && <div style={{ width: "15%" }}>
                                            <Button style={{ width: "100%" }} size="sm" variant="dark" onClick={() => createEntity("Location")}>Create</Button>
                                        </div>
                                        }
                                        {canEdit && <div style={{ width: "15%" }}>
                                            <Button style={{ width: "100%" }} size="sm" variant="dark" onClick={() => editEntity("Location")}>Edit</Button>
                                        </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Category</td>
                                <td>:</td>
                                <td>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: (canCreate) ? "65%" : "100%" }}>
                                            <SearchableSelect disabled={selectedLocation == ''} options={categories} selectedOption={selectedCategory} onSelect={handleSelectCategory} />
                                        </div>
                                        {canCreate && <div style={{ width: "15%" }}>
                                            <Button disabled={selectedLocation == ''} style={{ width: "100%" }} size="sm" variant="dark" onClick={() => createEntity("Category")}>Create</Button>
                                        </div>
                                        }
                                        {canEdit && <div style={{ width: "15%" }}>
                                            <Button disabled={selectedLocation == ''} style={{ width: "100%" }} size="sm" variant="dark" onClick={() => editEntity("Category")}>Edit</Button>
                                        </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textWrap: 'nowrap' }}>Collection Name</td>
                                <td>:</td>
                                <td>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: (canCreate) ? "65%" : "100%" }}>
                                            <SearchableSelect disabled={selectedCategory == '' || selectedLocation == ''} options={collections} selectedOption={selectedCollection} onSelect={handleSelectCollection} />
                                        </div>
                                        {canCreate && <div style={{ width: "30%" }}>
                                            <Button disabled={selectedCategory == '' || selectedLocation == ''} style={{ width: "100%" }} size="sm" variant="dark" onClick={() => createEntity("Collection")}>Create</Button>
                                        </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Style No</td>
                                <td>:</td>
                                <td>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        placeholder="Enter Style No."
                                        value={styleNo}
                                        onChange={handleStyleNo}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>File</td>
                                <td>:</td>
                                <td style={{ position: "relative" }}>
                                    <Form.Control
                                        id="fileInput"
                                        size="sm"
                                        type="file"
                                        accept=".fbx"
                                        // accept=".stl"
                                        // accept=".stl,.glb"
                                        onChange={handleFileChange}
                                    />
                                    <span style={{ fontSize: "12px", position: 'absolute', marginTop: '3px' }}>
                                        <span style={{fontWeight:'bold'}}>Upload criteria:</span> File type - .fbx, Software - MatrixGold.<br />Please ensure that the scale and position should be consistent across all files.
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3} style={{ textAlign: 'center' }}>
                                    <Button disabled={isLoading} onClick={!isLoading ? uploadModel : null} style={{ width: "50%", marginTop: "35px" }} size="sm" variant="dark">{isLoading ? <Spinner animation="border" size="sm" /> : 'Upload'}</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default UploadScreen;