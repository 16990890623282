import React, { useState } from 'react';
import { Nav, Navbar, Container, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/icons/vidaLogo.png';
import "./css/Header.css";

import { useAuth } from "react-oidc-context";

const Header = () => {
    const auth = useAuth();

    const location = useLocation();

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }
    // showAlert('SUCCESS: Support portal reloaded', 'lightgreen');

    const logoutUser = () => {
        // auth.removeUser();
        auth.signoutRedirect();
    }

    return (
        <>
            <Navbar className="navbar navbar-dark bg-dark" collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand >
                        <img
                            alt=""
                            src={Logo}
                            width="50"
                            height="40"
                            className="d-inline-block align-top"
                            style={{ backgroundColor: "white", marginLeft: "10px", borderRadius: "5px", padding: "0px 5px" }}
                        />
                    </Navbar.Brand>
                    <Navbar.Brand >ADMIN PORTAL</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/" className={location.pathname === '/' ? 'active' : ''}>Upload 3DModel</Nav.Link>
                            <Nav.Link as={Link} to="/collectionDetailsScreen" className={location.pathname === '/collectionDetailsScreen' ? 'active' : ''}>Collections Detail</Nav.Link>
                            <Nav.Link as={Link} to="/productDetailsScreen" className={location.pathname.startsWith('/productDetailsScreen') ? 'active' : ''}>Products Detail</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <div>

                    <Button size="sm" variant="secondary" onClick={logoutUser}>LOGOUT</Button>
                    {/* <span style={{color:"white"}}>{auth.user?.profile.given_name}</span> */}
                    </div>
                </Container>
            </Navbar>
            <Alerts />
        </>
    )
}

export default Header;