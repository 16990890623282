import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { BASE_URL } from '../lib/common.js'

import { useAuth } from "react-oidc-context";

const CreateEntity = ({ entity, show, setShow, setEntity, selectedLocation, selectedCategory, getLocations, getCategories, getCollections }) => {

    const auth = useAuth();
    const [userData, setUserData] = useState(null);

    const [entityValue, setEntityValue] = useState('');

    const [toggleAlert, setToggleAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertColor, setAlertColor] = useState('green');

    const alertStyle = {
        position: "absolute",
        right: "0",
        left: "0",
        zIndex: "10",
        backgroundColor: alertColor
    }

    function Alerts() {
        return (
            <>
                {toggleAlert && <div className="alert" style={alertStyle}>{alertMessage}</div>}
            </>
        )
    }

    function showAlert(message, color, time = 3000) {
        setAlertMessage(message);
        setAlertColor(color);
        setToggleAlert(true);
        setTimeout(function () { setToggleAlert(false) }, time)
    }

    const handleClose = () => {
        setShow(false)
        setEntity(null);
    };

    const handleEntityValueChange = (e) => {
        setEntityValue(e.target.value);
    }

    const createEntity = async () => {
        console.log(entityValue);
        if (entityValue == null || entityValue == '') {
            // setShow(false);
            showAlert(`Please enter ${entity} name!`, "lightcoral")
            setEntityValue('');
            return;
        }

        try {
            let obj = {}
            obj['user_name'] = userData?.preferred_username;
            if (entity === 'Location') {
                obj['location_name'] = entityValue;
            }
            if (entity === 'Category') {
                obj['location_name'] = selectedLocation;
                obj['category_name'] = entityValue;
            }
            else if (entity === 'Collection') {
                obj['location_name'] = selectedLocation;
                obj['category_name'] = selectedCategory;
                obj['collection_name'] = entityValue;
            }


            const response = await fetch(`${BASE_URL}add${entity}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj),
            });
            if (!response.ok) {
                const { success, message } = await response.json();
                showAlert(message, success == 'true' ? "lightgreen" : "lightcoral");
                return
            }
            const { success, message } = await response.json();
            setShow(false);
            showAlert(message, success == "true" ? "lightgreen" : "lightcoral");
            setEntityValue('');

            if (entity === 'Location') {
                getLocations();
            }
            if (entity === 'Category') {
                getCategories();
            }
            else if (entity === 'Collection') {
                getCollections();
            }
        } catch (error) {
            // setShow(false);
            showAlert(`Error Creating new ${entity}`, "lightcoral");
            setEntityValue('');
        }


        // setShow(false);
        // showAlert(`You have successfully created new ${entity} named ${entityValue}`,"lightgreen");
        // setEntityValue('');
    };

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.error && !auth.isLoading) {
          auth.signinRedirect();
        }
        if (auth.isAuthenticated) {
            // console.log("SHIVHSHIV auth", auth.user.profile);  
            setUserData(auth.user.profile);
        }
      }, [auth]);

    return (
        <div>
            <Alerts />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton style={{ fontFamily: "Nunito" }}>
                    <Modal.Title>Create {entity}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table style={{ width: "100%", fontFamily: "Nunito" }}>
                        <tbody>

                            <tr>
                                <td style={{ width: "20%" }}>{entity}</td>
                                <td >:</td>
                                <td style={{ width: "95%", float: "right" }}>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        placeholder={`Enter ${entity}`}
                                        value={entityValue}
                                        onChange={handleEntityValueChange}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "center" }}>
                    <Button style={{ fontFamily: "Nunito" }} variant="secondary" onClick={() => createEntity("")}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CreateEntity;